import React, { useState } from "react";
import AWS from "aws-sdk";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const s3 = new AWS.S3();
  const uploadToS3 = (data) => {
    const params = {
      Bucket: "snowflakes-email-list",
      Key: `name: ${data.name} - email: ${data.email}`,
      Body: JSON.stringify(data),
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading to S3:", err);
      } else {
        alert("Details Submitted", data);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email } = e.target.elements;

    const formData = {
      name: name.value,
      email: email.value,
    };

    uploadToS3(formData);

    setStatus("Submit");
    setEmail("");
    setName("");
  };

  return (
    <section className="contact-form" id="contact-form">
      <div className="form-container">
        {/* <div className="form-title-container">
          <div className="form-title">
            <h2>
              SIGN UP FOR <br />
              NOTIFICATIONS
            </h2>
          </div>
          <div className="form-subtitle">
            <h4>
              Be first to hear about our upcoming kickstarter campaign and
              receive email notification when the Snowflakes Clothing shop goes
              live
            </h4>
          </div>
        </div> */}
        <form className="form-input-container" onSubmit={handleSubmit}>
          <div className="form-inputs">
            <div className="form-inputs-name">
              <input
                type="text"
                id="name"
                placeholder="NAME"
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </div>
            <div className="form-inputs-email">
              <input
                type="email"
                id="email"
                placeholder="EMAIL"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
          </div>
          <div className="consent-container">
            <h3>GDPR Agreement *</h3>
            <div className="consent-input-container">
              <input type="checkbox" required />
              <h4>
                I consent to having this website store my submitted information
                so they can respond to my inquiry
              </h4>
            </div>
          </div>
          <div className="form-btn-container">
            <button className="contact-submit-btn" type="submit">
              {status}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
