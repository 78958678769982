import Hero from "./Hero";
import LatestCollection from "./LatestCollection";
import EthicalSplash from "./EthicalSplash";
// import LimitedCollection from "./LimitedCollection";
import Collections from "./Collections";
import ContactForm from "./ContactForm";
import GdprPopup from "./GdprPopup";

import { motion } from "framer-motion";

const Home = () => {
  return (
    <div className="home">
      <motion.div
        className="slide-in"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      />
      <motion.div
        className="slide-out"
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      />
      <Hero />
      <GdprPopup />
      <LatestCollection />
      <EthicalSplash />
      {/* <LimitedCollection /> */}
      <Collections />
      <ContactForm />
    </div>
  );
};

export default Home;
