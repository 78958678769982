import SFLogomark from "../multimedia/vectors/sf-logomarkBLUE.svg";
import SFLogomarkPINK from "../multimedia/vectors/sf-logomarkPINK.svg";
import CanvasImg from "../multimedia/images/Dress.jpg";
import CatalystImg from "../multimedia/images/Forest.jpg";
import CutImg from "../multimedia/images/PiggyBank.jpg";
import PinkOne from "../multimedia/vectors/pinkOne.svg";
import BlueTwo from "../multimedia/vectors/blueTwo.svg";
import BlackThree from "../multimedia/vectors/BlackThree.svg";

import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

import { useState } from "react";

const OurPurpose = () => {
  const [openCanvas, setOpenCanvas] = useState(false);
  const [openCatalyst, setOpenCatalyst] = useState(false);
  const [openCut, setOpenCut] = useState(false);

  const [selected, setSelected] = useState(false);

  const { scrollYProgress } = useScroll({ offset: [0, 0.15] });
  const scaleY = useTransform(scrollYProgress, [0, 0.5], ["90%", "100%"]);
  const scaleYSubtitle = useTransform(
    scrollYProgress,
    [0.25, 1],
    ["50%", "0%"]
  );

  const carousel = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delay: 0.075,
      },
    },
  };

  const carouselItem = {
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        type: "spring",
        duration: 1.4,
        bounce: 0,
      },
    },
    hidden: { x: 200 },
  };

  return (
    <div className="our-purpose">
      <section className="op-section">
        <div className="op-header-container">
          <motion.div
            className="op-header-title-container"
            style={{ scale: scaleY }}
          >
            <img src={SFLogomark} alt="snowflake logomark pink" />
            <h2>WHY SNOWFLAKES?</h2>
          </motion.div>
          <motion.div
            className="op-header-blurb-container"
            style={{ y: scaleYSubtitle }}
          >
            <h4>A collective movement toward a better world</h4>
          </motion.div>
        </div>
        <motion.div
          className="op-cards-container"
          variants={carousel}
          initial="hidden"
          whileInView="show"
          layout
        >
          <motion.div
            onClick={() => {
              setOpenCanvas(!openCanvas);
              setSelected("canvas");
            }}
            className={
              openCanvas && selected === "canvas"
                ? "op-card canvas-card card-open"
                : "op-card canvas-card"
            }
            variants={carouselItem}
            layoutId="canvas"
            transition={{ duration: 1, ease: [0.78, 0.01, 0.21, 1] }}
          >
            <img id="SFNum" src={PinkOne} alt="pink number one" />
            <img src={CanvasImg} alt="a woman in a colourful dress" />
            <div className="op-card-title-container canvas-title">
              <h2>
                A <br />
                CANVAS <br />
                FOR <br />
                SELF <br />
                EXPRESSION <br />
              </h2>
            </div>
            <AnimatePresence>
              {openCanvas && selected === "canvas" ? (
                <motion.div
                  className="op-card-blurb-container"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, ease: [0.78, 0.01, 0.21, 1] }}
                  exit={{ opacity: 0 }}
                >
                  <div className="op-card-blurb-para">
                    <p>
                      We are acutely aware of the struggles that trans and
                      gender-diverse people encounter as well as the gender
                      inequalities that plight peoples lives.
                    </p>
                  </div>
                  <div className="op-card-blurb-para">
                    <p>
                      With an open heart, we stand in solidarity with
                      marginalised communities and advocate for social justice
                      causes that align with our values.
                    </p>
                  </div>

                  <div className="op-card-blurb-para">
                    <p>
                      <strong>
                        We celebrate diversity and promote self-expression,
                      </strong>
                      offering gender-neutral designs that defy conventional
                      norms and welcome all.
                    </p>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </motion.div>

          <motion.div
            onClick={() => {
              setOpenCatalyst(!openCatalyst);
              setSelected("catalyst");
            }}
            className={
              openCatalyst && selected === "catalyst"
                ? "op-card catalyst-card card-open"
                : "op-card catalyst-card"
            }
            variants={carouselItem}
            layoutId="catalyst"
            transition={{ duration: 1, ease: [0.78, 0.01, 0.21, 1] }}
          >
            <img id="SFNum" src={BlueTwo} alt="blue number two" />
            <img src={CatalystImg} alt="a beautiful forest and lake scene" />
            <div className="op-card-title-container">
              <h2>
                A <br />
                CATALYST <br />
                FOR <br />
                CHANGE <br />
              </h2>
            </div>
            <AnimatePresence>
              {openCatalyst && selected === "catalyst" ? (
                <motion.div
                  className="op-card-blurb-container"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, ease: [0.78, 0.01, 0.21, 1] }}
                  exit={{ opacity: 0 }}
                >
                  <div className="op-card-blurb-para">
                    <p>
                      We embrace ethical and eco-friendly practices in every
                      thread, making choices that resonate with the environment
                      and future generations.
                    </p>
                  </div>
                  <div className="op-card-blurb-para">
                    <p>
                      Practicing kindness toward animals and humans alike. We
                      offer vegan products that contribute to the well-being of
                      animals and advocate for social causes that make a
                      positive impact.
                    </p>
                  </div>

                  <div className="op-card-blurb-para">
                    <p>
                      We recognise the need to safeguard our rainforests and
                      secure our trees and forests for future generations.
                      <strong>
                        We promise that we will plant one tree for every t-shirt
                        sold.
                      </strong>
                    </p>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </motion.div>
          <motion.div
            onClick={() => {
              setOpenCut(!openCut);
              setSelected("cut");
            }}
            className={
              openCut && selected === "cut"
                ? "op-card cut-card card-open"
                : "op-card cut-card"
            }
            variants={carouselItem}
            layoutId="cut"
            transition={{ duration: 1, ease: [0.78, 0.01, 0.21, 1] }}
          >
            <img id="SFNum" src={BlackThree} alt="black number three" />
            <img src={CutImg} alt="a cute pink piggy bank" />
            <div className="op-card-title-container cut-title">
              <h2>
                A <br />
                CUT <br />
                OF <br />
                THE <br />
                SUCESS <br />
              </h2>
            </div>
            <AnimatePresence>
              {openCut && selected === "cut" ? (
                <motion.div
                  className="op-card-blurb-container"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, ease: [0.78, 0.01, 0.21, 1] }}
                  exit={{ opacity: 0 }}
                >
                  <div className="op-card-blurb-para">
                    <p>
                      We strive to make a positive difference in the world
                      through our actions. Contributing to causes that align
                      with our values and promoting awareness and change. We
                      hope to encourage real change close to home and around the
                      world.
                    </p>
                  </div>
                  <div className="op-card-blurb-para">
                    <p>
                      <strong>
                        We pledge to donate 5% of annual profits to good causes.
                      </strong>
                    </p>
                  </div>

                  <div className="op-card-blurb-para">
                    <p>
                      We hope to connect with our audience and foster a sense of
                      belonging. Engage in meaningful conversations and
                      initiatives that resonate with our customers and
                      community.
                    </p>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </motion.div>
        </motion.div>
        <div className="op-blurb-container">
          <img src={SFLogomarkPINK} alt="pink cartoon snowflake" />
          <h4>
            <strong>"Let's Start an Avalanche"</strong> and capture the spirit
            of collective action, each individual effort, just like a snowflake,
            can come together to create a powerful force for change.
          </h4>
        </div>
      </section>
    </div>
  );
};

export default OurPurpose;
