import SFLogomark from "../multimedia/vectors/sf-logomark.svg";

import BLKT1 from "../multimedia/images/Qanuk/QANUK T100A BLK  product.png";
import BLKT1A from "../multimedia/images/Qanuk/QANUK T100A BLK alt.png";
import BLKT2 from "../multimedia/images/Qanuk/QANUK T100B BLK  product.png";
import BLKT2A from "../multimedia/images/Qanuk/QANUK T100B BLK alt.png";
import BLKT3 from "../multimedia/images/Qanuk/QANUK T100C BLK  product.png";
import BLKT3A from "../multimedia/images/Qanuk/QANUK T100C BLK alt.png";
import BLKT4 from "../multimedia/images/Qanuk/QANUK T100D BLK  product.png";
import BLKT4A from "../multimedia/images/Qanuk/QANUK T100D BLK alt.png";
import BLKT5 from "../multimedia/images/Qanuk/QANUK T100E BLK  product.png";
import BLKT5A from "../multimedia/images/Qanuk/QANUK T100E BLK alt.png";

import WHTT1 from "../multimedia/images/Qanuk/QANUK T100A WHT  product.png";
import WHTT1A from "../multimedia/images/Qanuk/QANUK T100A WHT alt.png";
import WHTT2 from "../multimedia/images/Qanuk/QANUK T100B WHT  product.png";
import WHTT2A from "../multimedia/images/Qanuk/QANUK T100B WHT alt.png";
import WHTT3 from "../multimedia/images/Qanuk/QANUK T100C WHT  product.png";
import WHTT3A from "../multimedia/images/Qanuk/QANUK T100C WHT alt.png";
import WHTT4 from "../multimedia/images/Qanuk/QANUK T100D WHT  product.png";
import WHTT4A from "../multimedia/images/Qanuk/QANUK T100D WHT alt.png";
import WHTT5 from "../multimedia/images/Qanuk/QANUK T100E WHT  product.png";
import WHTT5A from "../multimedia/images/Qanuk/QANUK T100E WHT alt.png";

import { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const LatestCollection = () => {
  const [hoverMe, setHoverMe] = useState(0);

  useEffect(() => {
    console.log(hoverMe);
  }, [hoverMe]);

  const { scrollYProgress } = useScroll({ offset: [0, 0.15] });
  const scaleY = useTransform(scrollYProgress, [0, 0.5], ["90%", "100%"]);
  const scaleYSubtitle = useTransform(
    scrollYProgress,
    [0.25, 1],
    ["50%", "0%"]
  );

  const carousel = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delay: 0.075,
      },
    },
  };

  const carouselItem = {
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        type: "spring",
        duration: 1.4,
        bounce: 0,
      },
    },
    hidden: { x: 200 },
  };

  return (
    <div className="latest-collection">
      <section className="lc-section">
        <div className="lc-header-container">
          <motion.div
            className="lc-header-title-container"
            style={{ scale: scaleY }}
          >
            <img src={SFLogomark} alt="snowflake logomark pink" />
            <h2>QANUK</h2>
            <h3>100</h3>
          </motion.div>
          <motion.div
            className="lc-header-blurb-container"
            style={{ y: scaleYSubtitle }}
          >
            <h4>
              The Qanuk range of limited-edition t-shirts are made from the
              highest quality organic cotton,
              <br />
              using certified ethical and sustainable production methods
            </h4>
          </motion.div>
        </div>
        <div className="lc-scroll-section">
          <div className="lc-h-scroll-holster">
            <motion.ul
              className="lc-h-scroll-container"
              dir="ltr"
              variants={carousel}
              initial="hidden"
              whileInView="show"
            >
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(1);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 1 ? (
                    <img src={BLKT1A} alt="they-person tshirt" />
                  ) : (
                    <img src={BLKT1} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100A</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(2);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 2 ? (
                    <img src={WHTT1A} alt="they-person tshirt" />
                  ) : (
                    <img src={WHTT1} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100A</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(3);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 3 ? (
                    <img src={BLKT2A} alt="they-person tshirt" />
                  ) : (
                    <img src={BLKT2} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100B</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(4);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 4 ? (
                    <img src={WHTT2A} alt="they-person tshirt" />
                  ) : (
                    <img src={WHTT2} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100B</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(5);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 5 ? (
                    <img src={BLKT3A} alt="they-person tshirt" />
                  ) : (
                    <img src={BLKT3} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100C</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(6);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 6 ? (
                    <img src={WHTT3A} alt="they-person tshirt" />
                  ) : (
                    <img src={WHTT3} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100C</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(7);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 7 ? (
                    <img src={BLKT4A} alt="they-person tshirt" />
                  ) : (
                    <img src={BLKT4} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100D</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(8);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 8 ? (
                    <img src={WHTT4A} alt="they-person tshirt" />
                  ) : (
                    <img src={WHTT4} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100D</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(9);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 9 ? (
                    <img src={BLKT5A} alt="they-person tshirt" />
                  ) : (
                    <img src={BLKT5} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100E</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
              <motion.li className="product-container" variants={carouselItem}>
                <motion.div
                  className="product-img-container"
                  onMouseEnter={() => {
                    setHoverMe(10);
                  }}
                  onMouseLeave={() => {
                    setHoverMe(0);
                  }}
                >
                  {hoverMe === 10 ? (
                    <img src={WHTT5A} alt="they-person tshirt" />
                  ) : (
                    <img src={WHTT5} alt="they-person tshirt" />
                  )}
                </motion.div>
                <div className="product-details-container">
                  <p>QANUK T100E</p>
                  <p>COMING SOON</p>
                </div>
              </motion.li>
            </motion.ul>
          </div>
          <div className="lc-button-container">
            {/* <button>
              <a href="#contact-form">
                <p>get notified</p>
              </a>
            </button> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LatestCollection;
