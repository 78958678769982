import SFLogoPINK from "../multimedia/vectors/sf-logomarkPINK.svg";
import FactScroller from "./FactScroller";

const OurMission = () => {
  return (
    <section className="our-mission">
      <div className="om-text-container">
        <div className="om-text-title-container">
          <h2>OUR MISSION</h2>
          <div className="om-text-title-img-container">
            <img src={SFLogoPINK} alt="pink cartoon snowflake" />
            <img src={SFLogoPINK} alt="pink cartoon snowflake" />
            <img src={SFLogoPINK} alt="pink cartoon snowflake" />
          </div>
        </div>
        <div className="om-text-blurb-container">
          <h4>"Treat the planet like home and everyone on it like family."</h4>{" "}
          <h4>
            Snowflakes Clothing is committed to redefining fashion as a force
            for good. We strive to provide ethically conscious and stylishly
            expressive clothing, which stand as statements of compassion,
            empowerment, and innovation.
          </h4>
        </div>
      </div>
      <FactScroller />
    </section>
  );
};

export default OurMission;
