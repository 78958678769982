import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";

import CottonSplash from "../multimedia/images/ethical-cotton.jpg";

const EthicalSplash = () => {
  const { scrollYProgress } = useScroll({ offset: [0, 0.5] });
  const scaleYImg = useTransform(scrollYProgress, [0.5, 1], ["70%", "100%"]);

  return (
    <div className="ethical-splash">
      <section className="splash-container">
        <motion.div
          className="ethical-splash-img-container"
          style={{ scale: scaleYImg }}
        >
          <img src={CottonSplash} alt="close up of cotton plant" />
          <div className="es-banner-text-container">
            <div className="es-banner-text">
              <h2 className="es-title-blur">
                ETHICAL
                <br />
                SUSTAINABLE
              </h2>
              <h2>WHO CARES?</h2>
              <h4 id="es-subtitle">
                At Snowflakes Clothing we are dedicated to normalising
                sustainable and ethical practices. <br />
                While we are proud to uphold the highest ethical standards, we
                feel no need to shout about it.
              </h4>
              <h4>If you care that we care...</h4>
              <div className="es-btn-container">
                <button>
                  <Link to="/our-story">
                    <h4>Learn More</h4>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default EthicalSplash;
