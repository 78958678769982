import { motion, useScroll, useTransform } from "framer-motion";

import EmpathyHug from "../multimedia/images/empathy-splash.jpg";

const EmpathySplash = () => {
  const { scrollYProgress } = useScroll({ offset: [0, 0.5] });
  const scaleYImg = useTransform(scrollYProgress, [0.5, 1], ["70%", "100%"]);

  return (
    <div className="empathy-splash">
      <section className="splash-container">
        <motion.div
          className="empathy-splash-img-container"
          style={{ scale: scaleYImg }}
        >
          <img src={EmpathyHug} alt="two women hug" />
          <div className="em-banner-text-container">
            <div className="em-banner-text">
              <h1>EMPATHY AS STANDARD</h1>
              <h3 id="em-subtitle">
                Our designers always seek to challenge the norms of fashion.
                Every design is a statement against conformity, a celebration of
                the beautiful diversity that makes us who we are.
              </h3>
              <h3 className="mobile-sub-remove">
                And beneath the surface, the commitment to ethics runs deep -
                every material sourced, every detail considered, and every
                decision made, is done so with a focus on sustainability,
                inclusivity, empathy and authenticity.
              </h3>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default EmpathySplash;
