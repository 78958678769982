import Clothes from "../multimedia/images/cloth-designs.jpg";

const HeroCS = () => {
  return (
    <div className="hero" id="hero">
      <section className="hero-banner">
        <div className="hero-img-container coming-soon-hero-spacer">
          <img src={Clothes} alt="A clothes designers workshop" />
          <div className="hero-banner-text-container coming-soon-hero-text-container">
            <div className="hero-banner-text coming-soon-hero-text">
              <h3>NEW COLLECTIONS</h3>
              <h2>COMING SOON!</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroCS;
