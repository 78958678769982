import Layout from "./components/Layout.jsx";
import Home from "./components/Home.jsx";
import OurStory from "./components/OurStory.jsx";
import ComingSoon from "./components/ComingSoon.jsx";
import Nav from "./components/Nav";
import PasswordScreen from "./components/PasswordScreen.jsx";

import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: "eu-west-1",
});

function App() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const webPassword = process.env.REACT_APP_SITE_PASSWORD;
  const location = useLocation();

  // return isSubmitted ? (
  return (
    <div className="App">
      <Nav />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="our-story" element={<OurStory />} />
            <Route path="coming-soon" element={<ComingSoon />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
  // ) : (
  //   <PasswordScreen setIsSubmitted={setIsSubmitted} webPassword={webPassword} />
  // );
}

export default App;
