import { motion, useScroll, useTransform } from "framer-motion";

import AvalancheSplash from "../multimedia/images/avalanche.jpg";

const Hero = () => {
  const { scrollYProgress } = useScroll({ offset: [0, 0.15] });
  const scaleYImg = useTransform(scrollYProgress, [0, 1], ["100%", "90%"]);

  return (
    <div className="hero" id="hero">
      <section className="hero-banner">
        <motion.div
          className="hero-img-container our-story-hero-spacer"
          style={{ scale: scaleYImg }}
        >
          <img src={AvalancheSplash} alt="An avalanche on a winter mountain" />
          <div className="hero-banner-text-container our-story-hero-text-container">
            <div className="hero-banner-text our-story-hero-text">
              <h1>
                JOIN <br />
                THE <br />
                AVALANCHE
              </h1>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default Hero;
