import { motion, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import SFSplashT from "../multimedia/images/SFSplash2.png";
import Neutral from "../multimedia/images/Neutral/Neutral® Logo+Certificates banner.png";

const Hero = () => {
  const { scrollYProgress } = useScroll({ offset: [0, 0.15] });
  const scaleY = useTransform(scrollYProgress, [0, 0.5], ["100%", "0%"]);
  const scaleYImg = useTransform(scrollYProgress, [0, 1], ["100%", "90%"]);

  return (
    <div className="hero" id="hero">
      <section className="hero-banner">
        <motion.div
          className="hero-colour-change"
          style={{ opacity: scaleY }}
        ></motion.div>
        <motion.div
          className="page-reveal"
          animate={{ y: 0 }}
          initial={{ y: "100%" }}
          transition={{
            delay: 1.2,
            duration: 1.1,
            ease: [0.78, 0.01, 0.21, 1],
          }}
        >
          <motion.div
            className="hero-img-container"
            style={{ scale: scaleYImg }}
          >
            <img src={SFSplashT} alt="T-shirt" />
            <div className="hero-banner-text-container">
              <div className="hero-banner-text">
                <h2>
                  DROPPING <br /> THIS WINTER
                </h2>
                <h4 className="hero-banner-subtitle">
                  Sustainable, gender-neutral streetwear. <br />
                  Help save the planet and look good while doing it.
                </h4>
                <div className="hero-btn-container">
                  {/* <button>
                    <a href="#contact-form">
                      <p>get notified</p>
                    </a>
                  </button> */}
                  <button>
                    <Link to="/our-story">
                      <h4>Learn More</h4>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.img
            style={{ opacity: scaleY }}
            className="neutral-logo"
            src={Neutral}
            alt="Neutral Logo and sertification"
          />
        </motion.div>
      </section>
    </div>
  );
};

export default Hero;
