import React, { useState, useEffect } from "react";

const GdprPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Check if the user has already accepted cookies
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    setShowPopup(!cookiesAccepted);
  }, []);

  const handleAcceptCookies = () => {
    // Hide the popup
    setShowPopup(false);

    // Set a cookie to remember the user's consent
    localStorage.setItem("cookiesAccepted", "true");
  };

  return (
    showPopup && (
      <div className="gdpr-popup">
        <div className="gdpr-content">
          <h2>Cookie Consent</h2>
          <p>
            We use cookies to ensure you get the best experience on our website.
            By continuing, you consent to our use of cookies.
          </p>
          <button onClick={handleAcceptCookies}>Accept</button>
          <button onClick={handleAcceptCookies}>Reject</button>
        </div>
      </div>
    )
  );
};

export default GdprPopup;
