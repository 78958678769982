import SFLogo from "../multimedia/vectors/SnowflakesWHT.svg";
import Snowflake from "../multimedia/vectors/sf-logomarkWHT.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-container">
        <div className="footer-logo-container">
          <Link to="/">
            <img src={SFLogo} alt="Snowflakes Logo" />
          </Link>
        </div>
        <div className="footer-links-container">
          <div className="footer-links commercial-links">
            <ul>
              {/* <li>
                <a href="#">
                  <h4>collections</h4>
                </a>
              </li>
              <li>
                <a href="#">
                  <h4>shop</h4>
                </a>
              </li> */}
              <li>
                <a href="#contact-form">
                  <h4>get notified</h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-links info-links">
            <ul>
              <li>
                <Link to="coming-soon">
                  <h4>coming soon</h4>
                </Link>
              </li>
              <li>
                <Link to="our-story">
                  <h4>our story</h4>
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-links social-links">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/snowflakesclothingco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>instagram</h4>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@snowflakesclothingco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>Tik Tok</h4>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <img
          className="footer-snowflake"
          src={Snowflake}
          alt="Snowflake logo-mark, a rounded cartoon snowflake"
        />
      </div>
    </section>
  );
};

export default Footer;
