import SFLogomark from "../multimedia/vectors/sf-logomark.svg";
import Nutaryuk from "../multimedia/images/nutaryuk-card.jpg";
import Hoodie from "../multimedia/images/comingsoonHOODIE.png";
import Colour from "../multimedia/images/colour-coming-soon.jpg";

const Collections = () => {
  return (
    <section className="collections" id="collections">
      <div className="collections-container">
        <div className="collections-cards-container">
          <div className="collections-card nutaryuk-card">
            <img src={Nutaryuk} alt="clothes rack" />
            <div className="collections-card-square"></div>
            <div className="collections-card-title-container">
              <h3>NUTARYUK</h3>
              <h4>coming soon</h4>
            </div>
          </div>
          <div className="collections-card coming-soon-card">
            <img src={Hoodie} alt="clothes rack" />
            <div className="collections-card-square"></div>
            <div className="collections-card-title-container">
              <h3>HOODIES</h3>
              <h4>coming soon</h4>
            </div>
          </div>
          <div className="collections-card colour-card">
            <img src={Colour} alt="clothes rack" />
            <div className="collections-card-square"></div>
            <div className="collections-card-title-container">
              <h3>COLOUR CLASH T</h3>
              <h4>coming soon</h4>
            </div>
          </div>
        </div>
        <div className="collections-subtitle-container">
          <img src={SFLogomark} alt="snowflake logomark pink" />
          <h4>
            We build our collections to last and encourage our customers to keep
            their Snowflakes pieces forever
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Collections;
