import React from "react";

const FactScroller = () => {
  return (
    <div className="fact-scroller">
      <div className="fact-scroll-holster">
        <div className="scroll-shine"></div>
        <div className="fact-scroll-mover primary">
          <div className="facts-container">
            <div className="fact">
              <h1>3182</h1>
              <h4>Litres of water used to make a single cotton T-shirt</h4>
            </div>
            <div className="fact">
              <h2>
                148 <br />
                Million
              </h2>
              <h4>Tonnes per year of fashion waste by 2050</h4>
            </div>
            <div className="fact">
              <h1>8000</h1>
              <h4>
                Synthetic chemicals used in the fashion manufacturing process
              </h4>
            </div>
            <div className="fact">
              <h1>16</h1>
              <h4>
                The number of said synthetic chemicals approved by the EPA
              </h4>
            </div>
            <div className="fact">
              <h2>
                4<br />
                Billion
              </h2>
              <h4>Tonnes per year of fashion C02 emissions</h4>
            </div>
          </div>
        </div>
        <div className="fact-scroll-mover secondary">
          <div className="facts-container">
            <div className="fact">
              <h1>3182</h1>
              <h4>Litres of water used to make a single cotton T-shirt</h4>
            </div>
            <div className="fact">
              <h2>
                148 <br />
                Million
              </h2>
              <h4>Tonnes per year of fashion waste by 2050</h4>
            </div>
            <div className="fact">
              <h1>8000</h1>
              <h4>
                Synthetic chemicals used in the fashion manufacturing process
              </h4>
            </div>
            <div className="fact">
              <h1>16</h1>
              <h4>
                The number of said synthetic chemicals approved by the EPA
              </h4>
            </div>
            <div className="fact">
              <h2>
                4<br />
                Billion
              </h2>
              <h4>Tonnes per year of fashion C02 emissions</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactScroller;
