import SFLogo from "../multimedia/vectors/SnowflakesWHT.svg";
import SFCross from "../multimedia/vectors/snowcross.svg";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Nav = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const opacityConverter = visible ? 1 : 0;
  const navTitleRevealVariant = {
    initial: { y: 0 },
    whileInView: { y: "-100%" },
    transition: {
      duration: 0.2,
      delay: 0.5,
      ease: [0.78, 0.01, 0.21, 1],
    },
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > 250 && currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <motion.header className="header" style={{ opacity: opacityConverter }}>
      <div className="header-container">
        <motion.ul
          className="header-links header-links--left"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 2.2, duration: 0.5 }}
        >
          <li className="header-link">
            <Link to="/our-story" className="header-link--our-story">
              <h4>our story</h4>
            </Link>
          </li>
          <li className="header-link">
            <Link to="/coming-soon" className="header-link--coming-soon">
              <h4>coming soon</h4>
            </Link>
          </li>
        </motion.ul>
        <div className="header-links header-links--logo">
          <Link to="/">
            <motion.img
              src={SFLogo}
              alt="Snowflakes Clothing logomark"
              animate={{
                y: ["100vh", "40vh", "0vh"],
                scale: [null, 3.5, 1],
              }}
              transition={{ duration: 2.3, ease: [0.78, 0.01, 0.21, 1] }}
            />
          </Link>
        </div>
        <motion.ul
          className="header-links header-links--right"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 2.2, duration: 0.5 }}
        >
          {/* <li className="header-link">
            <a href="#" className="header-link--collections">
              <h4>collections</h4>
            </a>
          </li>
          <li className="header-link">
            <a href="#" className="header-link--shop">
              <h4>shop</h4>
            </a>
          </li> */}
          <li className="header-link">
            <a href="#contact-form" className="header-link--kickstarter">
              <h4>get notified</h4>
            </a>
          </li>
        </motion.ul>

        <nav className="header-nav" id={navOpen ? "header-nav-open" : null}>
          <div className="nav-img-toggle-container">
            <div className="header-nav-logo">
              <motion.img
                src={SFLogo}
                alt="Snowflakes Clothing logomark"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 0.4 }}
                transition={{ delay: 0.75, duration: 0.25 }}
              />
            </div>
            <div className="nav-toggle">
              <motion.img
                whileTap={{ scale: 0.8 }}
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                src={SFCross}
                alt="an X to go back"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              />
            </div>
          </div>
          <ul className="header-nav-links">
            <li className="header-nav-link">
              <a
                className="header-nav-link-title"
                href="#collections"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <motion.h2
                  variants={navTitleRevealVariant}
                  initial="initial"
                  whileInView="whileInView"
                  transition={{
                    duration: 0.45,
                    delay: 0.1,
                    ease: [0.78, 0.01, 0.21, 1],
                  }}
                >
                  COLLECTIONS
                </motion.h2>
                <div className="nav-link-line"></div>
              </a>
            </li>
            {/* <li className="header-nav-link">
              <a
                className="header-nav-link-title"
                href="#"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <motion.h2
                  variants={navTitleRevealVariant}
                  initial="initial"
                  whileInView="whileInView"
                  transition={{
                    duration: 0.5,
                    delay: 0.15,
                    ease: [0.78, 0.01, 0.21, 1],
                  }}
                >
                  SHOP
                </motion.h2>
                <div className="nav-link-line"></div>
              </a>
            </li> */}
            <li className="header-nav-link">
              <a
                className="header-nav-link-title"
                href="#contact-form"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <motion.h2
                  initial={{ y: 0 }}
                  whileInView={{ y: "-100%" }}
                  transition={{
                    duration: 0.55,
                    delay: 0.25,
                    ease: [0.78, 0.01, 0.21, 1],
                  }}
                >
                  GET NOTIFIED
                </motion.h2>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <Link
                className="header-nav-link-title"
                to="/our-story"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <motion.h2
                  initial={{ y: 0 }}
                  whileInView={{ y: "-100%" }}
                  transition={{
                    duration: 0.6,
                    delay: 0.3,
                    ease: [0.78, 0.01, 0.21, 1],
                  }}
                >
                  OUR STORY
                </motion.h2>
                <div className="nav-link-line"></div>
              </Link>
            </li>
            <li className="header-nav-link">
              <Link
                className="header-nav-link-title"
                to="/coming-soon"
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
              >
                <motion.h2
                  initial={{ y: 0 }}
                  whileInView={{ y: "-100%" }}
                  transition={{
                    duration: 0.65,
                    delay: 0.35,
                    ease: [0.78, 0.01, 0.21, 1],
                  }}
                >
                  COMING SOON
                </motion.h2>
                <div className="nav-link-line"></div>
              </Link>
            </li>
          </ul>
          <motion.ul
            className="header-nav-socials"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              duration: 0.65,
              delay: 0.75,
              ease: [0.78, 0.01, 0.21, 1],
            }}
          >
            <motion.li className="header-nav-social">
              <a
                href="https://www.instagram.com/snowflakesclothingco"
                target="_blank"
                rel="noreferrer"
              >
                <h4>Instagram</h4>
              </a>
            </motion.li>
            <motion.li className="header-nav-social">
              <a
                href="https://www.tiktok.com/@snowflakesclothingco"
                target="_blank"
                rel="noreferrer"
              >
                <h4>TikTok</h4>
              </a>
            </motion.li>
            {/* <motion.li className="header-nav-social">
              <a href="#" target="_blank">
                <h4>Threads</h4>
              </a>
            </motion.li> */}
          </motion.ul>
        </nav>
        <div className="nav-btn-container"></div>
        <motion.div
          className="nav-btn"
          onClick={() => {
            setNavOpen(!navOpen);
          }}
          whileTap={{ scale: 0.9 }}
        ></motion.div>
      </div>
    </motion.header>
  );
};

export default Nav;
