import HeroOS from "./HeroOS.jsx";
import OurPurpose from "./OurPurpose.jsx";
import EmpathySplash from "./EmpathySplash.jsx";
import OurMission from "./OurMission.jsx";

import { motion } from "framer-motion";

const OurStory = () => {
  return (
    <div className="our-story">
      <motion.div
        className="slide-in"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      />
      <motion.div
        className="slide-out"
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      />
      <HeroOS />
      <OurPurpose />
      <EmpathySplash />
      <OurMission />
    </div>
  );
};

export default OurStory;
